@import url('https://fonts.googleapis.com/css2?family=Abel&family=Antic&family=Asap+Condensed&family=Bebas+Neue&family=Poiret+One&display=swap');

/* barra de rolagem custom firefox */
:root {
  scrollbar-color: rgb(122, 178, 255) rgb(25, 32, 37) !important;
  scrollbar-width: thin !important;
}

/* inicio custom scrollbar chrome */
::-webkit-scrollbar {
  background-color: #2c3e50;
  width: 0.625rem;
  height: 0.625rem;
}

::-webkit-scrollbar-thumb {
  background-color: #2495f8;
  border-radius: 0.625rem;
}
/* fim custom scrollbar chrome */


html {
  background-color: #2c3e50;

  -webkit-user-select: none; /* Chrome, Safari 3 */
  -moz-user-select: -moz-none;  /* Firefox */
  -ms-user-select: none;  /* IE10+ */
  -khtml-user-select: none;     /* Safari 2 */
  -webkit-touch-callout: none;  /* iPhone OS, Safari */
  user-select: none; /* Possível implementação no futuro */
}
main {
  margin-bottom: 13.4375rem;
}
.p-card {
  border-radius: 1.25rem;
}

h6 {
  font-family: 'Abel', sans-serif;
  color: #ffff;
  font-size: 2.5rem;
  text-align: center;
  text-transform: uppercase;
  margin-left: 0.625rem;
  margin-top: 1.25rem;

}
h4 {
  font-family: 'Bebas Neue', cursive;
  font-size: 2.5rem;
}
p {
  font-family: 'Asap Condensed', sans-serif;
  font-size: 3.75rem;
}
h3 {
  color: #6c757d;
  font-family: 'Asap Condensed', sans-serif;
  font-size: 0.9375rem;
}
img {
  border-radius: 1.25rem;
}
img.acende {
  opacity: 0.3;
  -moz-opacity: 0.3;
  Filter: Alpha(Opacity=30)
  }

img.acende:hover {
  opacity: 1.0;
  -moz-opacity: 1.0;
  Filter: Alpha(Opacity=100)
  }
/* 
img.grow:hover
  {
          -webkit-transform: scale(1.3);
          -ms-transform: scale(1.3);
          transform: scale(1.3);
  }

h4.grow:hover
  {
          -webkit-transform: scale(1.3);
          -ms-transform: scale(1.3);
          transform: scale(1.3);
  }

  p.grow:hover
  {
          -webkit-transform: scale(1.3);
          -ms-transform: scale(1.3);
          transform: scale(1.3);
  } */
  .footer {
    padding: 0rem;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #1d232a;
    color: #9e9e9e;
    text-align: center;
    font-family: 'Poiret One', sans-serif;
    letter-spacing: 0.125rem;
    z-index: 1;
  }
